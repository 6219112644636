import React, { useEffect } from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Companies from "../components/main/companies";
import Sticky from "../components/main/sticky";
import Feature from "../components/main/feature";
import Rules from "../components/main/rules";
import Discover from "../components/main/discover";
import Seo from "../components/layout/seo";
import Grid from "@mui/material/Grid";
import Subscribe from "../components/misc/check_list";
import dots from "/src/images/dots.svg";
import logo from "/src/images/iso_color.png";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

const dotsimg1 = {
  width: "90px",
  right: "5%",
  marginTop: "-4%",
  position: "absolute",
};

const dotsimg2 = {
  width: "90px",
  left: "5%",
  marginTop: "-4%",
  position: "absolute",
};

let cs_theme = createTheme({
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo
          title="Custom Surgical - Microscope Adaptor and Applications | Custom Surgical"
          description="Custom Surgical is democratizing hardware and software to record microscope procedures easily and quickly without any professional engineer to attach or cables to share live."
        />

        <Subscribe postName={"main_page"} />
        <Grid container xs={12} className="gif_main">
          <h1 className="text_over">
            {" "}
            Your own microscope,
            <div style={{ marginTop: "1rem" }}>
              {" "}
              but{" "}
              <span style={{ color: "#FFA100", marginLeft: "5px" }}>
                {" "}
                SMARTER <br />
              </span>
            </div>
            <button className="shop" style={{ marginTop: "10%" }}>
              <a href="/store" style={{ color: "white", fontSize: "24px" }}>
                Go to Store
              </a>
            </button>
          </h1>
        </Grid>
        <Rules
          style={{ scrollSnapStop: "always", scrollSnapType: "y mandatory" }}
        />
        <img style={dotsimg1} src={dots} alt="Dots" />
        <div style={{ marginBottom: "10%" }}></div>
        <Sticky />
        <div style={{ marginBottom: "5%" }}></div>

        <div style={{ marginBottom: "5%" }}></div>
        <div style={{ marginBottom: "10%" }}></div>
        <img style={dotsimg2} src={dots} alt="Dots" />
        {/* <Feature /> */}
        <Discover />
        <Companies />
        <div style={{ marginBottom: "5%" }}></div>
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

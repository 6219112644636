import React from "react";
import logo from "/src/images/logo.svg";

const Discover = () => {
  return (
    <>
      <section style={{ backgroundColor: "#202026" }}>
        <div
          style={{
            maxWidth: "1000px",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: "10em",
            textAlign: "center",
            paddingBottom: "7em",
          }}
        >
          <img src={logo}></img>
          <h1
            style={{ color: "white", fontWeight: "400", padding: "0px 20px" }}
          >
            Discover what Custom Surgical can do for you try our product demo or
            talk to our sales team.
          </h1>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a
              href="https://wa.me/491782064226?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
              target="t_blank"
            >
              <button className="talk">TALK TO SALES</button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Discover;

import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { StaticImage } from "gatsby-plugin-image";

const Feature = () => {
  return (
    <Container
      xs={12}
      maxWidth={"lg"}
      style={{ marginTop: "5em", marginBottom: "5em" }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "1em" }}>
        If you compare, you'll see that MicroREC makes much more sense
        for&nbsp;you.
      </h2>
      <Grid item xs={10}>
        <table>
          <tr>
            <td style={{ border: "none" }}>Feature Comparison</td>
            <td style={{ border: "none" }}>
              <div
                style={{
                  width: "50px",
                  height: "auto",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <StaticImage
                  src="../../images/iso_color.png"
                  alt="Logo of Custom Surgical"
                />
              </div>
            </td>
            <td style={{ border: "none" }}>
              Other systems with <b>CCD camera</b>
            </td>
            <td style={{ border: "none" }}>
              Other systems with <b>smartphones</b>
            </td>
          </tr>
          <tbody>
            <>
              <tr>
                <td style={{ border: "1px solid #ccc" }}>
                  Video Resolution up to 4K
                </td>

                <td style={{ border: "1px solid #ccc" }}>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/yes.png"
                      alt="Image of tick"
                    />
                  </div>
                </td>
                <td style={{ border: "1px solid #ccc" }}>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/no.png"
                      alt="Image of cross"
                    />
                  </div>
                </td>
                <td style={{ border: "1px solid #ccc" }}>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/no.png"
                      alt="Image of cross"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Install it yourself</td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/yes.png"
                      alt="Image of tick"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/no.png"
                      alt="Image of cross"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/yes.png"
                      alt="Image of tick"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Take the system anywhere with you</td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/yes.png"
                      alt="Image of tick"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/no.png"
                      alt="Image of cross"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/yes.png"
                      alt="Image of tick"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Universal compatibility with any slit lamp and microscope
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/yes.png"
                      alt="Image of tick"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/no.png"
                      alt="Image of cross"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/no.png"
                      alt="Image of cross"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Compatible with any smartphone</td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/yes.png"
                      alt="Image of tick"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/no.png"
                      alt="Image of cross"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/no.png"
                      alt="Image of cross"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Dedicated mobile applications</td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/yes.png"
                      alt="Image of tick"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/no.png"
                      alt="Image of cross"
                    />
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      width: "30px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <StaticImage
                      src="../../images/don't.png"
                      alt="Image of uncertanty"
                    />
                  </div>
                </td>
              </tr>
            </>
          </tbody>
        </table>
      </Grid>
    </Container>
  );
};
export default Feature;

import React from "react";
import gif2 from "/src/images/gifs/Don't miss any interesting case.gif";
import gif3 from "/src/images/gifs/combo.webp";
import dr from "/src/images/DrAhmed.png";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const block = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
};

const text = {
  width: "40%",
  fontSize: "20px",
};

const img = {
  width: "100%",
  borderRadius: "10px",
  objectFit: "cover",
  maxHeight: "350px",
};

const isBrowser = typeof window !== "undefined";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

if (isBrowser) {
  window.addEventListener("scroll", reveal);
}

const Rules = () => {
  return (
    <Container maxWidth={"lg"}>
      <Grid
        rowSpacing={5}
        container
        className="container"
        marginTop={2}
        marginBottom={2}
      >
        <Grid container item style={block}>
          <Grid item xs={10} sm={5}>
            <img style={img} src={gif2}></img>
          </Grid>
          <Grid item xs={10} sm={5} style={text} marginTop={{ xs: 2, sm: 0 }}>
            <hr
              style={{
                width: "10%",
                margin: "0",
                height: "2px",
                backgroundColor: "rgb(255, 161, 0)",
                marginBottom: "2em",
              }}
            ></hr>
            <h2 style={{ marginTop: "0", color: "#1a202c" }}>
              Capture <b style={{ color: "#FFA100" }}>high-quality</b> images
            </h2>
            <div>
              The most interesting cases appear on the field or when you don't
              expect them. Get the{" "}
              <b style={{ fontWeight: "300", color: "#FFA100" }}>
                <a
                  href="/09-why-are-high-quality-images-so-important-in-ophthalmology/"
                  target="_blank"
                  className="links_post"
                >
                  best quality recordings
                </a>{" "}
              </b>{" "}
              with an optical system that fits in your hand, and you can attach
              them in less time than you took to read this text.
            </div>
          </Grid>
        </Grid>

        <Grid container item style={block} marginTop={2} marginBottom={2}>
          <Grid
            item
            xs={10}
            sm={5}
            style={text}
            order={{ xs: 2, sm: 1 }}
            marginTop={{ xs: 2, sm: 0 }}
          >
            <hr
              style={{
                width: "10%",
                margin: "0",
                height: "2px",
                backgroundColor: "rgb(255, 161, 0)",
                marginBottom: "2em",
              }}
            ></hr>
            <h2 style={{ marginTop: "0", color: "#1a202c" }}>
              <b style={{ color: "#FFA100" }}>Connect </b>your work and save
              time{" "}
            </h2>
            <div>
              Forget about cables, computers, or USB devices. Your time should
              be spent on what really matters.{" "}
              <b style={{ fontWeight: "300", color: "#FFA100" }}>
                {" "}
                <a
                  href="/19-3-ways-to-stream-your-microscopic-procedures-on-the-TV-without-any-cable/"
                  target="_blank"
                  className="links_post"
                >
                  Share your procedures
                </a>
              </b>{" "}
              with your colleagues, students, or other partners in two clicks.{" "}
            </div>
          </Grid>
          <Grid item xs={10} sm={5} order={{ xs: 1, sm: 2 }}>
            <img style={img} src={gif3}></img>
          </Grid>
        </Grid>

        <Grid container item style={block} marginTop={2} marginBottom={2}>
          <Grid item xs={10} sm={5}>
            <img style={img} src={dr}></img>
            <div style={{ textAlign: "center" }}>
              Dr. Ike Ahmed, in Canada, mentoring Dr. Ticiana de Francesco, in
              Brazil.
            </div>
          </Grid>
          <Grid item xs={10} sm={5} style={text} marginTop={{ xs: 2, sm: 0 }}>
            <hr
              style={{
                width: "10%",
                margin: "0",
                height: "2px",
                backgroundColor: "rgb(255, 161, 0)",
                marginBottom: "2em",
              }}
            ></hr>

            <h2 style={{ marginTop: "0", color: "#1a202c" }}>
              <b style={{ color: "#FFA100" }}>Share</b> your expertise with the
              world{" "}
            </h2>
            <div>
              Get all your procedures in your hands{" "}
              <b style={{ fontWeight: "300", color: "#FFA100" }}>
                <a
                  href="/07-5-ways-to-use-MicroREC-as-a-working-tool/"
                  target="_blank"
                  className="links_post"
                >
                  whenever you need them.
                </a>
              </b>{" "}
              Review as many times as it is necessary and track your progress.
              Or your students progress - at a distance if that's the case.
              That's the medical technology you need.{" "}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Rules;

import React from "react";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import CETC from "/src/images/trust/CETC.png";
import LMU from "/src/images/trust/LMU.png";
import Clinica from "/src/images/trust/Clinica.png";
import Oftalmo from "/src/images/trust/Oftalmo.png";
import Google from "../../images/Google Cloud.png";

const Companies = () => {
  return (
    <>
      <h2
        style={{
          textAlign: "center",
          marginBottom: "2em",
          marginTop: "2em",
          padding: "0px 10px",
        }}
      >
        Companies and Institutions believing in us
      </h2>
      <Swiper
        centeredSlides={false}
        centeredSlidesBounds={true}
        slidesPerView={"auto"}
        breakpoints={{
          600: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 3,
          },

          1150: {
            slidesPerView: 4,
          },
          1550: {
            slidesPerView: 5,
          },
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        loop={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={CETC} style={{ width: "310px" }}></img>
        </SwiperSlide>

        <SwiperSlide>
          <img src={LMU} style={{ width: "310px" }}></img>
        </SwiperSlide>

        <SwiperSlide>
          <img src={Clinica} style={{ width: "310px" }}></img>
        </SwiperSlide>

        <SwiperSlide>
          <img src={Oftalmo} style={{ width: "310px" }}></img>
        </SwiperSlide>

        <SwiperSlide>
          <img src={Google} style={{ width: "310px" }}></img>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Companies;

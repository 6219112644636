import React from "react";
import micro from "/src/images/slilamp_micro.svg";
import connection from "./Microscope.png";
import phone from "/src/images/LogoSmart.png";
import software from "/src/images/software.png";
import Grid from "@mui/material/Grid";

const Stickyy = () => {
  return (
    <div>
      <Grid container maxWidth={"lg"} className="sticky">
        <Grid
          item
          xs={10}
          sm={5}
          className="left--section"
          marginTop={{ sm: 20, xs: 0 }}
        >
          <h2 className="sticky_h1">
            You already have{" "}
            <b style={{ color: "#00C5B7", fontStyle: "italic" }}>almost</b>{" "}
            everything you need
          </h2>
        </Grid>
        <Grid
          container
          item
          xs={10}
          sm={5}
          id="right--section"
          marginTop={{ xs: 10, sm: 0 }}
        >
          <Grid item xs={12}>
            <h3>
              <div className="sticky_text"> 01. Smartphone</div>
            </h3>
            <div className="first--sticky--block">
              <div className="first--sticky--bg"></div>
              <div className="first--sticky--photo">
                <img src={phone} alt="Smartphone" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <h3>
              <div className="sticky_text"> 02. Microscope or Slitlamp</div>
            </h3>
            <div className="first--sticky--block">
              <div className="second--sticky--bg"></div>
              <div className="first--sticky--photo2">
                <img src={micro} alt="Slitlamp or Microscope" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        maxWidth={"lg"}
        className="sticky"
        marginTop={{ xs: 0, sm: 20 }}
      >
        <Grid
          item
          xs={10}
          sm={5}
          className="left--section"
          order={{ xs: 1, sm: 2 }}
          marginTop={{ xs: 0, sm: 20 }}
        >
          <h2 className="sticky_h1">
            The <b style={{ color: "#FFA100" }}>smartest</b> solution
          </h2>
        </Grid>
        <Grid
          order={{ xs: 2, sm: 1 }}
          item
          container
          xs={10}
          sm={5}
          marginTop={{ xs: 5, sm: 0 }}
          id="right--section"
          style={{ marginRight: "auto" }}
        >
          <Grid item xs={12} marginTop={{ xs: 10, sm: 0 }}>
            <h3 style={{ marginTop: "0" }}>
              <div className="sticky_text">A digitalization tool</div>
            </h3>
            <p>
              Find the{" "}
              <a href="/store" className="links_post" target="_blank">
                best device to digitize
              </a>{" "}
              your microscopes and slit lamps.
            </p>
            <div className="first--sticky--block">
              <div className="third--sticky--bg"></div>
              <div className="first--sticky--photo2">
                <img src={connection} alt="Missing connection" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginTop: "25%" }}>
              <div className="sticky_text">
                The Medical Data Management Platform
              </div>
            </h3>
            <p>
              <a href="/connect" className="links_post" target="_blank">
                <b> Keep your data safe and secure</b>
              </a>
              , access it from any device, any time from anywhere.
            </p>
            <div
              className="first--sticky--block"
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <div
                className="second--sticky--bg"
                style={{ marginTop: 0 }}
              ></div>
              <div className="first--sticky--photo4">
                <img src={software} alt="Software" style={{ width: "100%" }} />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Stickyy;
